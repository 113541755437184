<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:500px;background-color:#fff">
      <b-row class="mb-5">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps"/>
        </b-col>
        <b-col xl="12" lg="12" sm="12" md="12" v-if="!isLoading">
          <h3 class="content-header-title text-primary-dark text-center" v-if="invoice.status == 2">{{ $t('Finished') }}</h3>
          <div class="mt-3">
            <b-row class="p-2">
              <b-col xl="10" lg="12" sm="12" md="12" class="offset-xl-1 text-center">
                <div v-if="invoice.status == 2">
                  <span class="finished-icon d-flex justify-content-center ">
                    <b-avatar variant="success" icon="check"></b-avatar>
                  </span>
                  <span class="finished-title mt-2">{{ $t('Payment Completed') }}</span>
                  <p class="finished-detail">{{ $t('Your bill receipt Number') }}: {{ invoice.ref_invoice }}</p>
                </div>
                <div v-else>
                  <span class="finished-icon d-flex justify-content-center ">
                    <b-icon v-if="invoice.status == 5" variant="warning" scale="2" icon="check-circle"></b-icon>
                    <b-icon v-else variant="danger" scale="2" icon="x-circle"></b-icon>
                  </span>
                  <span class="finished-title mt-2">{{ invoice.status_name }}</span>
                </div>
              </b-col>
               <b-col xl="12" lg="12" sm="12" md="12" class="d-flex justify-content-center mt-4 mb-4">
                  <b-button class="mr-2" size="" variant="outline-secondary" @click="onBilling">
                    {{ $t('Go to Billing') }}
                  </b-button>
                  <b-button class="ml-2" size="" variant="primary" @click="onReceipt" v-if="invoice.status == 2">
                   {{ $t('View Receipt') }}
                  </b-button>
               </b-col>
            </b-row>
          </div>
        </b-col>
         <b-col  xl="12" lg="12" sm="12" md="12" v-else>
          <b-row class="justify-content-center">
            <b-spinner class="text-primary-dark" label="Loading"></b-spinner>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import StepFormRegister from '../../../components/form/StepFormRegister'
import 'vue-select/dist/vue-select.css'
import Api from '../../../api/api'

export default {
  name: 'Checkout',
  title: ' | Checkout',
  components: {
    StepFormRegister
  },
  data () {
    return {
      currentStep: 3,
      titleListSteps: [this.$t('Bill Info'), this.$t('Payment'), this.$t('Finished')],
      loading: true,
      invoiceId: null,
      invoice: null,
      isLoading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    this.invoiceId = this.$route.params.invoiceId
    this.invoiceDetail(this.invoiceId)
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onBilling () {
      this.$router.push({ name: 'billing' })
    },
    onReceipt () {
      this.$router.push({ name: 'billingInvoiceDetail', params: { invoiceId: this.invoiceId } })
    },
    invoiceDetail (invoiceId) {
      this.isLoading = true
      Api.invoiceDetail({ invoiceId: invoiceId }).then((response) => {
        this.invoice = response.data
        this.isLoading = false
      })
        .catch(() => {
          this.messageError = ''
          this.isLoading = false
        })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
